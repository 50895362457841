// const dev = 'http://1.116.186.197:8666'
// const dev = 'https://wms-service.sfcchina.com.cn'
const dev = 'https://newwms-service.sfcchina.com.cn'

const test = 'https://newwms-service.sfcchina.com.cn'

const prod = 'https://wms-service.laowanhui.com'

export {
  dev,
  test,
  prod,
}
