import {getToken, setToken, getCookie, setCookie} from '@/utils/auth'


const user = {
  state: {
    token: getToken() || "",
    userInfo: getCookie("userInfo") || {},
  },
  mutations: {
    setToken: (state, token) => {
      setToken(token)
      state.token = token
    },
    setUser: (state, data) => {
      setCookie("userInfo", data)
      state.userInfo = data
    },
    
  },
  getters : {
    areaList: state => state.areaList,
  },
  actions: {
    invokerSetUserInfo(context, data) {
      context.commit('setToken', data.token)
      context.commit('setUser', data)
    },
  }
}

export default user