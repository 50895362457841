import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken,setToken } from '@/utils/auth' // 验权
import store from '@/store' // 验权

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home')
  },
  {
    path: '/printSendOrder',
    name: 'printSendOrder',
    component: () => import('@/views/printSendOrder')
  },
  {
    path: '/printSendShop',
    name: 'printSendShop',
    component: () => import('@/views/printSendShop')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach( (to, from, next) => {
  if (getToken()) {
    let { userInfo, lightSystem } = store.state.user
    next()
  } else {
    let data = to.path.split('/')
    if (data.indexOf('login') > -1) {
      next()
    } else {
      // 否则全部重定向到登录页
      next(`/login`)
    }
  }
})

export default router

