import Cookies from 'js-cookie'

const TokenKey = 'wmsSortToken'

// cookie:token 操作
export function getToken() {
  return getCookie(TokenKey);
}

export function setToken(token) {
  setCookie(TokenKey, token);
}

export function getCookie(key) {
  let data = Cookies.get(key)
  return data? JSON.parse(data) : data;
}


export function setCookie(key, value) {
  return Cookies.set(key, JSON.stringify(value));
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function removeCookie(key) {
  return Cookies.remove(key);
}
