import axios from 'axios'
import { dev, test, prod } from '@/utils/config'
import {getToken} from '@/utils/auth'
import {Message, MessageBox} from 'element-ui'
import router from '@/router'

let baseURL = ''
if (process.env.NODE_ENV === 'production') {
  if (process.env.VUE_APP_FLAG === 'test') {
    baseURL = test
    console.log('test',process.env.VUE_APP_FLAG)
  } else {
    baseURL = prod
    console.log('prod',process.env.VUE_APP_FLAG )
  }
} else {
  baseURL = dev
  console.log('dev',process.env.VUE_APP_FLAG )
}

// 创建axios实例
const service = axios.create({
  baseURL: baseURL, // api 的 base_url
  timeout: 120000, // 请求超时时间：120秒，两分钟
})
// request拦截器
service.interceptors.request.use(
  config => {
    config.headers['wmsToken'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    config.headers['client'] = 'web' // 让每个请求携带自定义token 请根据实际情况自行修改
    return config
  },
  error => {
    // Do something with request error
    console.log("request拦截器错误:" + error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.config.responseType == "arraybuffer") {// 是否为导出
      return response.data
    } else if (res.code !== '000' && res.code !== '001' && !res.resultCode ) {
      Message({
        message: res.msg,
        showClose: true,
        type: 'info',
        duration: 3 * 1000,
        offset: 300
      })
      return Promise.reject(response.data)
    } else {
      return response.data
    }
  },
  error => {
    switch (error.response.status) {
      case 401:   // 登录超时
        router.push('/login')
        break;
    }
    Message({
          message: error.message,
          showClose: true,
          type: 'error',
          duration: 3 * 1000,
          offset: 300
      })
    return Promise.reject(error)
  }
)
export function post({url, params, config}) {
  return new Promise((resolve, reject) => {
    service
      .post(url, params,config)
      .then(
      response => {
        resolve(response);
      },
      err => {
          reject(err);
      }
      )
      .catch(error => {
      reject(error);
      });
  });
}
export function get({url, data, config}) {
  let str = ''
	if (data) {
		str = '?'
		for(let x in data) {
			str += x + '=' + data[x] + '&'
		}
	}
  return new Promise((resolve, reject) => {
    service
      .get(url + str.substr(0, str.length - 1), config)
      .then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      )
      .catch(error => {
        reject(error);
      });
  });
}
export default service

